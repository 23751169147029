<template>
  <v-card>
    <v-card-title>Configurações da matriz e filiais</v-card-title>

    <v-data-table
      :headers="headers"
      :items="companyBranches"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click="$router.push('/filiais/cadastrar')"
            >Cadastrar +</v-btn
          >
        </v-toolbar>
      </template>

      <template v-slot:[`item.companyName`]="{ item }">
        <div v-if="item.master">
          {{ item.companyName }}
          <v-chip class="ml-3" color="info" small>Matriz</v-chip>
        </div>
        <div v-else>
          {{ item.companyName }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Editar filial"
          @click="editCompanyBranch(item.id)"
          small
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Deletar filial"
          :disabled="item.master"
          @click="deleteCompanyBranch(item.id)"
          small
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn
          class="mr-2"
          title="Ver associações"
          @click="viewAssociations(item.id)"
          small
          icon
        >
          <v-icon>mdi-link</v-icon>
        </v-btn>

        <v-btn
          class="mr-2"
          title="Configurações"
          @click="viewConfigs(item.id)"
          small
          icon
        >
          <v-icon>mdi-cogs</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>



<script>
import errorHandler from "@/helpers/error_handler";
export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Razão social", value: "companyName" },
      { text: "Nome fantasia", value: "fantasyName" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    CBAssociationsDialog: false,
    companyBranches: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async loadCompanyBranches() {
      try {
        this.loading = true;

        let url = `/company-branches`;

        const response = await this.$axios.get(url);

        this.setCompanyBranches(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setCompanyBranches(data) {
      const cpData = [...data];
      this.companyBranches = cpData;
    },
    async deleteCompanyBranch(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar a filial com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/company-branches/${id}`;

        await this.$axios.delete(url);

        this.deleteCompanyBranchFromTable(id);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteCompanyBranchFromTable(id) {
      const index = this.companyBranches.findIndex((p) => p.id === id);

      this.companyBranches.splice(index, 1);
    },
    editCompanyBranch(id) {
      this.$router.push(`/filiais/${id}`);
    },
    viewAssociations(id) {
      this.$router.push(`/filiais/${id}/associacoes`);
    },
    viewConfigs(id) {
      this.$router.push(`/filiais/${id}/configuracoes`);
    },
  },
  computed: {},
  created() {
    this.loadCompanyBranches();
  },
};
</script>

<style>
</style>